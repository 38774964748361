/* LandingPageV2 classes */

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it. */
.topbarBehindModal {
  z-index: initial;
}

.searchNavbar {
  position: sticky;
  position: -webkit-sticky;
  top: var(--topbarHeight);
  z-index: 1;
}

@media only screen and (min-width: 768px) {
  .searchNavbar {
    top: var(--topbarHeightDesktop);
  }
}

.topbarAboveHero {
  /* override positioning of topbar container to be absolutely on top. */
  position: fixed;
}

.button {
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}

/* SearchNavbar classes */

.searchSectionItem {
  outline: 1px solid var(--gray40);
  background-color: white;
  height: 39px;
}

@media (min-width: 1200px) {
  .section {
    flex: 1;
  }
}

.iconButton {
  height: inherit;
  width: inherit;
  padding: 8px !important; /* Override default MuiIconButton styles */
}

/* SearchResults classes */

.newSearchInProgress {
  opacity: 0.1;

  /* Setting the temporary height so that scroll behaviors work */

  /* Number based on recommended banner image height. */
  height: 630px;
}

.searchResultsWrapper {
  max-width: var(--contentMaxWidth);
  margin-left: auto;
  margin-right: auto;
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}
