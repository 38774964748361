.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 20px;
  padding-bottom: 30px;
  border: none;
}
