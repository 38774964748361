.root {
  display: inline-block;
}

.label {
  font-family: var(--buttonFontFamily), var(--fontFamily), 'sofiapro', sans-serif !important;
  font-weight: var(--buttonFontWeight);
  font-size: var(--buttonFontSize);
  line-height: var(--buttonLineHeight);
  letter-spacing: var(--buttonLetterSpacing);
  display: flex;
  align-items: center;
  width: auto;
  height: 39px;
  min-height: 0;
  margin: 0;
  padding: 8px;
  border: none;
  border-radius: var(--borderRadius);
  background-color: transparent;
  color: var(--matterColor);
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: var(--buttonTextTransform);
  transition: all var(--transitionStyleButton);
  cursor: pointer;
  outline: none;
}

.label:hover,
.label:focus {
  outline: none;
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
}

.labelSelected {
  font-family: var(--buttonFontFamily), var(--fontFamily), 'sofiapro', sans-serif !important;
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--buttonFontSize);
  line-height: var(--buttonLineHeight);
  letter-spacing: var(--buttonLetterSpacing);
  display: block;
  width: auto;
  height: 39px;
  min-height: 0;
  margin: 0;
  padding: 8px;
  border: none;
  border-radius: unset;
  background-color: var(--gray80);
  color: white;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: var(--buttonTextTransform);
  transition: all var(--transitionStyleButton);
  cursor: pointer;
}

.labelSelected:hover,
.labelSelected:focus {
  color: white;
  background-color: var(--gray80);
  border: none;
}

.labelEllipsis {
  white-space: nowrap;
}

.labelSpacer {
  width: 4px;
}

.arrowDownHovered {
  opacity: 0.6;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);
  left: 0;

  /* Layout */
  min-width: 300px;
  width: 100%;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: var(--borderRadiusHalf);
  transition: var(--transitionStyleButton);
}

@media only screen and (min-width: 768px) {
  .popup {
    width: auto;
    left: auto;
  }
}

.popupSize {
  padding: 17px 0;
}

.modalSize {
  padding: 32px 0;
}

.filterWrapper {
  padding: 0 30px 16px 30px;
  overflow-y: auto;
  max-height: 332px;
}

.footerWrapper {
  padding: 0 30px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.divider {
  background-color: var(--gray20);
  margin: 0 0 16px 0;
}

.actionButton {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
}
