.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  height: auto;

  /* sticky result summary in mobile */
  position: sticky;
  top: 0;
  z-index: var(--zIndexModal);
}

.searchResultSummary {
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  background-color: var(--matterColorBright);
}

@media only screen and (min-width: 768px) {
  .searchResultSummary {
    margin-top: 8px;
  }
}

.buttons {
  display: flex;
}

.filtersButton {
  height: auto;
  min-height: 39px;
  border: none;
  border-radius: var(--borderRadiusHalf);
}

.filtersButtonSelected {
  height: auto;
  min-height: 39px;
  border: none;
  background-color: var(--gray80);
  border-radius: var(--borderRadiusHalf);
}

.modalContainer {
  width: 100%;
  padding: 94px 24px;
  display: flex;
  background-color: white;
}

.modalHeadingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 31px;
  border-bottom: 1px solid var(--matterColorNegative);
}

[class*='filterLabel'] {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.resetAllButton {
  font-weight: var(--fontWeightMedium);
  color: var(--gray40);

  /* Layout */
  display: inline;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 24px;
  top: 24px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  line-height: 1;
}

.resetAllButton:focus,
.resetAllButton:hover {
  color: var(--gray100);
  cursor: pointer;
}

.filtersWrapper {
  /* add bottom margin so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  padding-bottom: 180px;
}

.showListingsContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 18px 24px;
  background: var(--matterColorLight);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: var(--zIndexModal);
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: var(--borderRadiusHalf);
}

.dialogHeader {
  padding: 0 !important;
  position: sticky;
  top: 0;
  z-index: 2;
}
